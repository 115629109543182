




















import { Vue } from "vue-property-decorator";
export default class Links extends Vue {}
