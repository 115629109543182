




















import { Component, Vue } from "vue-property-decorator";
import Links from '@/components/Links.vue'; // @ is an alias to /src
import Construction from '@/components/Construction.vue';

@Component({
  components: {
    Links, Construction
  },
})
export default class Home extends Vue {}
