











import { Vue } from 'vue-property-decorator';
export default class Construction extends Vue {}
